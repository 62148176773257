/* eslint-disable import/no-anonymous-default-export */
import { Navigate } from 'react-router-dom';
import Layout from '../layouts/index'
import NotFind from '../components/notFind/index'
import { menuRouter } from './menuRouter';

export default [
  {
    path: '/',
    element: <Layout showHeaders={false}/>,
    children: [
      {
        index: true,
        element: <Navigate to='Home' />,
      },
      ...menuRouter
    ],
  },
  {
    title: '404',
    path: '*',
    element: <NotFind />,
  },
];
