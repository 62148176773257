import React, { useRef, useEffect } from 'react';

const useHorizontalScroll = ({ children, ...layoutProps }) => {
  const elRef = useRef();
  useEffect(() => {
    const el = elRef.current.children[0]; // 这个是通过ref获取被包住的需要滚动的元素
    if (el) { // 判断是否有这个元素
      const onWheel = e => {
        if (el?.scrollWidth > elRef?.current?.scrollWidth) { // 判断是否需要横向滚动
          e.preventDefault();
          el.scrollTo({ // 滑动的动画
            left: el.scrollLeft + (e.deltaY * 5),
            behavior: 'smooth',
          });
        }
      };
      el.addEventListener('wheel', onWheel, true); // 需要监听的
      return () => {
        el.removeEventListener('wheel', onWheel, true);
      };
    }
    return elRef;
  }, []);
  return (
    <div
      {...layoutProps}
      ref={elRef} // ref
    >
      {children}
    </div>
  );
};

export default useHorizontalScroll;