import { Drawer, Dropdown, Modal } from 'antd';
import Logo from './images/aibot.svg';
import menu from './images/menu.svg';
import Close from './images/close.svg';
import Frame from './images/Frame.svg';
import Vector from './images/Vector.svg';
import Rectangle from './images/Rectangle.svg';
import add from './images/add.svg';
import exportImg from './images/export.svg';

import { menuRouter } from '../../router/menuRouter';
import { useState } from 'react';
// import { useNavigate } from 'react-router-dom';
import './header.scss';

const Header = (props) => {
  // const navigate = useNavigate();
  let [show, setShow] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onSelect = (e, label) => {
    e.stopPropagation();
    console.log('123123123', label);
    props?.scrollToAnchor(label)
    setShow(false)
    // navigate(path);
  };

  const onConnectWallet = () => {
    setIsModalOpen(true)
    setShow(false)
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const items = [
    {
      key: '1',
      disabled: true,
      label: (
        <>
          <div className='title'>
            <img src={Rectangle} alt="" />
            <div className='name'>0xae7c...364a</div>
          </div>
          <div className='line' />
        </>
      ),
    },
    {
      key: '2',
      label: (
        <div className='value'>
          <img src={add} alt="" />
          <div className='name'>Connect another wallet</div>
        </div>
      ),
    },
    {
      key: '3',
      label: (
        <div className='value'>
          <img src={exportImg} alt="" />
          <div className='name'>Disconnectwallets</div>
        </div>
      ),
    }
  ];
  return (
    <div className='height-main'>
      <div className='logo'>
        <img src={Logo} width={235} alt='' />
      </div>
      <div className='menuItem'>
        {
          menuRouter?.map(item =>
          (<div
            className='navigation'
            key={item.key}
            onClick={(e) => onSelect(e, item.label)}
          >
            {item.label}
          </div>)
          )
        }
      </div>
      <Dropdown menu={{ items }} placement="bottom" autoAdjustOverflow={false} overlayClassName='head-down'>
        <div className='connectWallet' onClick={onConnectWallet}>Connect Wallet</div>
      </Dropdown>
      <div className='menuIcon' onClick={() => setShow(!show)} ><img src={menu} alt="" /></div>
      <Drawer
        title=""
        placement='right'
        closable={true}
        onClose={() => setShow(false)}
        visible={show}
        className="connect-drawer"
        key='right'
        width={300}
      >
        <div className='nav-mobile'>
          {
            menuRouter.map(item => {
              return (
                <div className='nav-item' key={item.label} onClick={(e) => onSelect(e, item.label)}>
                  {item.label}
                </div>
              )
            })
          }
        </div>
        <div className='connect-btn' onClick={onConnectWallet}>Connect Wallet</div>
      </Drawer>
      <Modal
        title="Connect Wallect"
        open={isModalOpen}
        onOk={handleOk}
        className='wallect-modal'
        wrapClassName='modalWrap'
        onCancel={handleCancel}
        width={440}
        closeIcon={false}
        footer={null}
      >
        <div className='connect-items'>
          <img src={Frame} alt="" />
          <div className='name'>MetaMask</div>
        </div>
        <div className='connect-items'>
          <img src={Vector} alt="" />
          <div className='name'>WalletConnect</div>
        </div>
        <img className='closeImg' src={Close} alt="" onClick={handleCancel} />
      </Modal>

    </div>
  );
};
export default Header;
