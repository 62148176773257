import Home from '../pages/index'

const menuRouter = [
  {
    key: 1,
    label: 'Introduction',                           
    path: 'Home',
    element: <Home />,
  },
  {
    key: 2,
    label: 'How It Works',
    path: 'Home',
    element: <Home />,
  },
  {
    key: 3,
    label: 'Roadmap',
    path: 'Home',
    element: <Home />,
  },
  {
    key: 4,
    label: 'Tokenomics',
    path: 'Home',
    element: <Home />,
  },
  {
    key: 5,
    label: 'Solutions',
    path: 'Home',
    element: <Home />,
  },
  {
    key: 6,
    label: 'FAQs',
    path: 'Home',
    element: <Home />,
  },
];


export {menuRouter}