/* eslint-disable array-callback-return */
import { Fragment, useState, useEffect } from 'react';
import Header from '../layouts/header/index';
import HorizontalScroll from './HorizontalScroll';
// import titleImg from './img/title.svg';
import dogImg from './img/dog.png';
// import worksImg from './img/works.svg';
// import automatedImg from './img/automated.svg';
import zkSync from './img/tabList/zkSync.png';
import LayerZero from './img/tabList/LayerZero.png';
import Linea from './img/tabList/Linea.png';
import BaseImg from './img/tabList/Base.png';
import Taiko from './img/tabList/Taiko.png';
import BRIDGE from './img/tabConntent/BRIDGE.png'
import Frame2 from './img/tabConntent/era_arrows_white.svg'
import eswap from './img/tabConntent/eswap.svg'
import LENDING from './img/tabConntent/LENDING.svg'
import NFTMINT from './img/tabConntent/NFTMINT.svg'
import OTHERS from './img/tabConntent/OTHERS.svg'
import STAKING from './img/tabConntent/STAKING.svg'
import ZE from './img/tabConntent/ZE.svg'
import FrameGOAL from './img/tabConntent/FrameGOAL.png'
import TEVAERA from './img/tabConntent/TEVAERA.png'
import REactORFU from './img/tabConntent/REactORFU.png'
import eralend from './img/tabConntent/eralend.png'
import Framevelovore from './img/tabConntent/Framevelovore.png'
import ezk from './img/tabConntent/ezk.png'
import NFTPINAS from './img/tabConntent/NFTPINAS.svg'
import elements from './img/tabConntent/element.svg'
import zumi from './img/tabConntent/zumi.svg'
import mute from './img/tabConntent/mute.svg'
import syncswap from './img/tabConntent/syncswap-light.png'
// import TechnicalRoadmapRoad from './img/TechnicalRoadmapRoad.svg'
import TokenAIBotToken from './img/TokenAIBotToken.svg'
import Vector from './img/Vector.png';
import leftImg from './img/leftImg.png';
import rightImg from './img/rightImg.png';
// import AIBotFeaturesImg from './img/AIBotFeatures.svg';
import farming from './img/farming.svg';
import detection from './img/detection.svg';
import tracking from './img/tracking.svg';
import TRUSTEDBACKD from './img/TRUSTEDBACKD.svg'
// import automated from './img/weanswered.svg'
// import GETTODAY from './img/GETTODAY.svg'
import Rectangle56 from './img/AIBotWeb/Rectangle56.png';
import Rectangle57 from './img/AIBotWeb/Rectangle57.png';
import Rectangle58 from './img/AIBotWeb/Rectangle58.png';
import Rectangle59 from './img/AIBotWeb/Rectangle59.png';
import Rectangle60 from './img/AIBotWeb/Rectangle60.png';
import Rectangle61 from './img/AIBotWeb/Rectangle61.png';
import Rectangle62 from './img/AIBotWeb/Rectangle62.png';
import Rectangle63 from './img/AIBotWeb/Rectangle63.png';
import Rectangle64 from './img/AIBotWeb/Rectangle64.png';
import Rectangle65 from './img/AIBotWeb/Rectangle65.png';
import Rectangle66 from './img/AIBotWeb/Rectangle66.png';
import Rectangle67 from './img/AIBotWeb/Rectangle67.png';
import Rectangle68 from './img/AIBotWeb/Rectangle68.png';
import Rectangle69 from './img/AIBotWeb/Rectangle69.png';
import completed from './img/completed.png'
//TaikoList
import orbiterlight from './img/TaikoList/orbiter-light.svg';
import finance from './img/TaikoList/rai-finance-logo-dark.png';
import taikoLight from './img/TaikoList/taiko-light.svg';
import logonft from './img/TaikoList/logo-nft-s2-me.svg';
//BaseList
import aavelogo from './img/BaseList/aave-logo.svg';
import baselogo from './img/BaseList/base-logo-light.svg';
import hoplogo from './img/BaseList/hop-logo-light.svg';
import mean_finance from './img/BaseList/mean_finance.svg';
import odos_logo_white from './img/BaseList/odos_logo_white.png';
import sushiswaplogo from './img/BaseList/sushi-swap-logo.svg';
import zoralogolight from './img/BaseList/zora-logo-light.png';
//LineaList
import cashmere from './img/LineaList/cashmere.svg';
import celer from './img/LineaList/celer.svg';
import dForce from './img/LineaList/dForce.svg';
import echoDEx from './img/LineaList/echoDEx.svg';
import linealogolight from './img/LineaList/linea-logo-light.svg';
import mendi_finance_light from './img/LineaList/mendi_finance_light.png';
import VELOCOREwordmarkwhite from './img/LineaList/VELOCORE-wordmark-white.svg';
import zkHoldem from './img/LineaList/zkHoldem.svg';
import zonic from './img/LineaList/zonic.png';
//LayerZeroList
import altitude from './img/LayerZeroList/altitude.svg';
import blur from './img/LayerZeroList/blur.png';
import hashflowlogoblack from './img/LayerZeroList/hashflow-logo-black.svg';
import holograph from './img/LayerZeroList/holo-graph.png';
import interswap2 from './img/LayerZeroList/inter-swap-2.png';
import l2_marathon from './img/LayerZeroList/l2_marathon.png';
import melaxy from './img/LayerZeroList/melaxy.png';
import mugenfinance from './img/LayerZeroList/mugen-finance.svg';
import omniBTC from './img/LayerZeroList/omniBTC.png';
import omnix from './img/LayerZeroList/omnix.svg';
import stargate_logo from './img/LayerZeroList/stargate_logo.svg';
import synapse_light from './img/LayerZeroList/synapse_light.png';
import venusLogo from './img/LayerZeroList/venus-logo.png';

import { Tabs, Progress } from "antd";
import './style.scss';

const tabList = [
  { icon: zkSync, name: 'zkSync' },
  { icon: LayerZero, name: 'LayerZero' },
  { icon: Linea, name: 'Linea' },
  // { icon: Arbitrum, name: 'Arbitrum' },
  { icon: BaseImg, name: 'Base' },
  { icon: Taiko, name: 'Taiko' }
];
const tabContentList = [
  {
    icon: BRIDGE,
    name: 'BRIDGE',
    imgList: [orbiterlight, Frame2, celer],
  },
  {
    icon: eswap,
    name: 'SWAP',
    imgList: [syncswap, mute, zumi]
  },
  {
    icon: NFTMINT,
    name: 'NFT MINT',
    imgList: [elements, NFTPINAS]
  },
  {
    icon: STAKING,
    name: 'STAKING',
    imgList: [syncswap, ezk, Framevelovore]
  },
  {
    icon: LENDING,
    name: 'LENDING',
    imgList: [eralend, REactORFU]
  },
  {
    icon: OTHERS,
    name: 'OTHERS',
    imgList: [TEVAERA, FrameGOAL, ZE]
  }
];
const LayerZeroList = [
  {
    icon: BRIDGE,
    name: 'BRIDGE',
    imgList: [synapse_light, altitude, stargate_logo]
  },
  {
    icon: eswap,
    name: 'SWAP',
    imgList: [hashflowlogoblack, venusLogo, interswap2]
  },
  {
    icon: NFTMINT,
    name: 'NFT MINT',
    imgList: [l2_marathon, omnix, holograph]
  },
  {
    icon: STAKING,
    name: 'STAKING',
    imgList: [cashmere, interswap2, mugenfinance]
  },
  {
    icon: LENDING,
    name: 'LENDING',
    imgList: [omniBTC]
  },
  {
    icon: OTHERS,
    name: 'OTHERS',
    imgList: [melaxy, blur]
  }
];
const LineaList = [
  {
    icon: BRIDGE,
    name: 'BRIDGE',
    imgList: [linealogolight, hoplogo, celer]
  },
  {
    icon: eswap,
    name: 'SWAP',
    imgList: [VELOCOREwordmarkwhite, cashmere, echoDEx]
  },
  {
    icon: NFTMINT,
    name: 'NFT MINT',
    imgList: [zonic]
  },
  {
    icon: STAKING,
    name: 'STAKING',
    imgList: [VELOCOREwordmarkwhite, cashmere]
  },
  {
    icon: LENDING,
    name: 'LENDING',
    imgList: [mendi_finance_light]
  },
  {
    icon: OTHERS,
    name: 'OTHERS',
    imgList: [zkHoldem, dForce]
  }
];
// const ArbitrumList = [
//   {
//     icon: BRIDGE,
//     name: 'BRIDGE',
//     imgList: [Frame1, Frame2, Frame3]
//   },
//   {
//     icon: eswap,
//     name: 'SWAP',
//     imgList: [syncswap, mute, zumi]
//   },
//   {
//     icon: NFTMINT,
//     name: 'NFT MINT',
//     imgList: [elements, NFTPINAS]
//   },
//   {
//     icon: STAKING,
//     name: 'STAKING',
//     imgList: [syncswap, ezk, Framevelovore]
//   },
//   {
//     icon: LENDING,
//     name: 'LENDING',
//     imgList: [eralend, REactORFU]
//   },
//   {
//     icon: OTHERS,
//     name: 'OTHERS',
//     imgList: [TEVAERA, FrameGOAL, ZE]
//   }
// ];
const BaseList = [
  {
    icon: BRIDGE,
    name: 'BRIDGE',
    imgList: [hoplogo, baselogo]
  },
  {
    icon: eswap,
    name: 'SWAP',
    imgList: [odos_logo_white, mean_finance, sushiswaplogo]
  },
  {
    icon: NFTMINT,
    name: 'NFT MINT',
    imgList: [zoralogolight]
  },
  {
    icon: STAKING,
    name: 'STAKING',
    imgList: [mean_finance]
  },
  {
    icon: LENDING,
    name: 'LENDING',
    imgList: [aavelogo]
  }
];
const TaikoList = [
  {
    icon: BRIDGE,
    name: 'BRIDGE',
    imgList: [taikoLight, orbiterlight]
  },
  {
    icon: eswap,
    name: 'SWAP',
    imgList: [taikoLight, finance]
  },
  {
    icon: NFTMINT,
    name: 'NFT MINT',
    imgList: [logonft]
  }
];

const leftIllustrate = [{
  icon: Vector,
  name: "Buy/Sell Tax",
  value: "5% tax applied to each transaction of $AIBot"
},
{
  icon: Vector,
  name: "First 6 months",
  value: "1% for LP, 4% for team"
},
{
  icon: Vector,
  name: "Buy/Sell Tax",
  value: "1% for LP, 2% for revenue sharing, 2% for team"
}
];
const leftIllustrateTwo = [{
  icon: Vector,
  name: "Printer goes brrr",
  value: "Token holders are eligible for 50% off subscription fee and 25% revenue share"
},
{
  icon: Vector,
  name: "Farm together",
  value: "$AIBot holders can refer users and earn 5% of all fees collected from referred users."
},
{
  icon: Vector,
  name: "Earn together",
  value: "Token holders can burn $AIBot for $xAIBot which earns 50% revenue share."
}
];
const TOKENDISTRIBUTION = [
  { value: '500,000', name: 'Team & Founder (5%)', schedule: 5, color: '#AFBA2C' },
  { value: '500,000', name: 'CEX Listing (5%)', schedule: 5, color: '#E93455' },
  { value: '4,000,000', name: 'Supply Added to Liquidity (40%)', schedule: 40, color: '#E98B34' },
  { value: '5,000,000', name: 'Seed (50%)', schedule: 50, color: '#12AAFF' }
]
const AIBotFeatures = [
  { img: farming, name: 'Automated Airdrop Farming', tip: 'Generate wallets and automatically farm airdrops across multiple EVM-compatible chains.', width: 162 },
  { img: tracking, name: 'Airdrop Tracking', tip: 'Track your airdrop farming progress with data of airdrop participation history, rewards earned, and upcoming airdrops.', width: 144 },
  { img: detection, name: 'Anti-Sybil Detection', tip: 'Sybil-detection free airdrop farming through offering different curated routes that represents real on-chain interactions.', width: 200 }
]
const trustedBackedList = [
  { id: 1, img: Rectangle56, name: '@AlfaDAO_' },
  { id: 2, img: Rectangle57, name: '@UnidentifiedDao' },
  { id: 3, img: Rectangle58, name: '@DannyCrypt' },
  { id: 4, img: Rectangle59, name: '@GrindingPoet' },
  { id: 5, img: Rectangle60, name: '@0xxghost' },
  { id: 6, img: Rectangle61, name: '@HomerSigma' },
  { id: 7, img: Rectangle62, name: '@0xxghost' },
  { id: 8, img: Rectangle63, name: '@AlfaDAO_' },
  { id: 9, img: Rectangle64, name: '@UnidentifiedDao' },
  { id: 10, img: Rectangle65, name: '@DannyCrypt' },
  { id: 11, img: Rectangle66, name: '@GrindingPoet' },
  { id: 12, img: Rectangle67, name: '@0xxghost' },
  { id: 13, img: Rectangle68, name: '@HomerSigma' },
  { id: 14, img: Rectangle69, name: '@0xxghost' }
]
const list = [
  { date: 'Q2, 2023', id: 1, width: '170px', catdList: [{ status: "completed", value: 'R&D' }, { status: "completed", value: 'Fundraising' }] },
  {
    date: 'Q3, 2023',
    id: 2,
    width: '363px',
    catdList: [
      { status: "progress", value: "AIBot MVP release" },
      { status: "progress", value: "$LOOT launch with full utilities" },
      { status: "progress", value: "Debut on 3+ chains with full features" },
      { status: "progress", value: "Prototype Premium Features" },
      { status: "progress", value: "Airdrop tracking dashboard" },
      { status: "progress", value: "Adding a new chain every 2 weeks" },
      { status: "progress", value: "Launch referral program" },
      { status: "progress", value: "Launch ambassador program" }
    ]
  },
  {
    date: 'Q4, 2023', id: 3, width: '500px', catdList: [
      { status: "unfinished", value: 'Add more advanced automating tasks' },
      { status: "unfinished", value: 'Perfect the referral program to encourage user growth' },
      { status: "unfinished", value: 'Add more customizable options for users' },
      { status: "unfinished", value: 'Develop portfolio management feature for users' },
      { status: "unfinished", value: 'Integrate more chains & protocols' },
      { status: "unfinished", value: 'Develop Partnerships to grow user base' }
    ]
  },
  {
    date: 'Q1, 2023', id: 4, width: '500px', catdList: [
      { status: "unfinished", value: 'Implement additional security measures, such as two-factor authentication and encryption' },
      { status: "unfinished", value: "Optimize the bot's performance and scalability for handling a growing user base" },
      { status: "unfinished", value: 'Develop a referral program to encourage user growth' }
    ]
  },
  {
    date: 'Q12, 2022', id: 5, width: '500px', catdList: [
      { status: "unfinished", value: 'Implement additional security measures, such as two-factor authentication and encryption' },
      { status: "unfinished", value: "Optimize the bot's performance and scalability for handling a growing user base" },
      { status: "unfinished", value: 'Develop a referral program to encourage user growth' }
    ]
  }
];

const Albot = () => {
  const [data, setData] = useState([
    { id: 1, title: "Meet AIBot", tips: 'Type <span>/start</span> to <a>@AIBot</a> <br/> on Telegram' },
    { id: 2, title: "Transfer funds", tips: 'Follow the guide to complete onboarding.' },
    { id: 3, title: "Start Farming", tips: 'Farm manually or automatically.' }
  ]);
  const [questions, setQuestions] = useState([
    { id: 1, value: 'What is AIBot?', show: true },
    { id: 2, value: 'What is airdrop Tasks & Routes?', show: false },
    { id: 3, value: 'Who defines the airdrop tasks?', show: false },
    { id: 4, value: 'How tasks are triggled and how often?', show: false }
  ])
  const [tabValue, setTabValue] = useState('zkSync');
  const [steplist, setSteplist] = useState(list);
  const [catdId, setCatdId] = useState(1);
  const [showHeadBg, setShowHeadBg] = useState(false);
  const onSelectItems = (id) => {
    const list = questions.map(res => {
      if (res.id === id) {
        res.show = !res.show
      }
      return res
    })
    setQuestions(list)
  }
  const handleScroll = (e) => {
    const scrollTop = e.srcElement.scrollingElement.scrollTop;
    setShowHeadBg(scrollTop > 70);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToAnchor = (anchorName) => {
    if (anchorName) {
      let anchorElement = document.getElementById(anchorName);
      if (anchorElement) { anchorElement.scrollIntoView({ block: 'center', behavior: 'smooth' }); }
    }
  }

  return (
    <div className={`albot-main ${showHeadBg ? 'show-head-bg' : ''}`}>
      <Header scrollToAnchor={scrollToAnchor} />
      <div className="header-title" id='Introduction'>
        {/* <img className="title" src={titleImg} alt="" /> */}
        <div className='title'>HELLO, HUMAN <br /> READY TO AIbot?</div>
        <div className="tip">
          AIBot is a Telegram bot that automates on-chain interactions and acts as a streamline frontend for hundreds of chains. Anyone can create or follow automated tasks that could help with objectives such as Airdrop Farming.
        </div>
        <div className='but'>Meet AIBot</div>
        <div className='dog'>
          <img className='dogImg' src={dogImg} alt="" width={300} />
        </div>
      </div>
      <div className="card-works" id='How It Works'>
        <div className='card-title'>How It Works</div>
        <div className='list'>
          {data && data.map((res, index) => {
            return (
              <div className='items' key={res.id + index}>
                <div className='ball'>
                  <div className='colored-circle'>{res.id}</div>
                  {index + 1 !== data.length && <div className='dashed-line' />}
                </div>
                <div className='title'>{res.title}</div>
                <div className='tips' dangerouslySetInnerHTML={{ __html: res.tips }}></div>
              </div>
            )
          })}
        </div>
        <div className='buttom'>Visit DOCS</div>
      </div>
      <div className='automated'>
        <div className='does'>What AIBot does?</div>
        {/* <img className='title' src={automatedImg} alt="" /> */}
        <div className='title'>Your Airdrop Automated <br />by aiBot</div>
        <div className='tip'>AIBot performs a wide range of automated tasks across different protocols, on supported chains.</div>
        <div className='tabs'>
          <Tabs
            activeKey={tabValue}
            defaultActiveKey={tabValue}
            className='albot-tab'
            onTabClick={(value) => setTabValue(value)}
          >
            {tabList && tabList.map((res) => {
              return (
                <Tabs.TabPane
                  tab={
                    <div className="flexCenter">
                      <img src={res.icon} alt="" width={32} />
                      <span className='tabName'>{res.name}</span>
                    </div>}
                  key={res.name}
                />
              )
            })}
          </Tabs>
        </div>
        <div className='m-tabs'>
          {tabList && tabList.map((res) => {
            return (
              <div
                className={`flexCenter ${tabValue === res.name ? "selectItem" : ""}`}
                onClick={() => setTabValue(res.name)}
              >
                <img src={res.icon} alt="" width={32} />
                <span className='tabName'>{res.name}</span>
              </div>
            )
          })}
        </div>
        <div className='tabContent'>
          {tabValue === 'zkSync' && tabContentList && tabContentList.map((res, index) => {
            return (
              <Fragment key={res.name + index}>
                <div className='tab-item'>
                  <div className='tab-head'>
                    <img src={res.icon} alt="" />
                    <span>{res.name}</span>
                  </div>
                  <div className='tab-foot'>
                    {res?.imgList.map((item, key) => (
                      <div className='card-bg' key={key + res.name}> <img src={item} alt="" /></div>
                    ))}
                  </div>
                </div>
                {index + 1 !== tabContentList.length && <div className='lines' />}
              </Fragment>
            )
          })}
          {tabValue === 'LayerZero' && LayerZeroList && LayerZeroList.map((res, index) => {
            return (
              <Fragment key={res.name + index}>
                <div className='tab-item'>
                  <div className='tab-head'>
                    <img src={res.icon} alt="" />
                    <span>{res.name}</span>
                  </div>
                  <div className='tab-foot'>
                    {res?.imgList.map((item, key) => (
                      <div className='card-bg' key={key}> <img src={item} alt="" /></div>
                    ))}
                  </div>
                </div>
                {index + 1 !== LayerZeroList.length && <div className='lines' />}
              </Fragment>
            )
          })}
          {tabValue === 'Linea' && LineaList && LineaList.map((res, index) => {
            return (
              <Fragment key={res.name + index}>
                <div className='tab-item'>
                  <div className='tab-head'>
                    <img src={res.icon} alt="" />
                    <span>{res.name}</span>
                  </div>
                  <div className='tab-foot'>
                    {res?.imgList.map((item, key) => (
                      <div className='card-bg' key={key}> <img src={item} alt="" /></div>
                    ))}
                  </div>
                </div>
                {index + 1 !== LineaList.length && <div className='lines' />}
              </Fragment>
            )
          })}
          {/* {tabValue === 'Arbitrum' && ArbitrumList && ArbitrumList.map((res, index) => {
            return (
              <Fragment key={res.name + index}>
                <div className='tab-item'>
                  <div className='tab-head'>
                    <img src={res.icon} alt="" />
                    <span>{res.name}</span>
                  </div>
                  <div className='tab-foot'>
                    {res?.imgList.map((item, key) => (
                      <div className='card-bg' key={key}> <img src={item} alt="" /></div>
                    ))}
                  </div>
                </div>
                {index + 1 !== tabContentList.length && <div className='lines' />}
              </Fragment>
            )
          })} */}
          {tabValue === 'Base' && BaseList && BaseList.map((res, index) => {
            return (
              <Fragment key={res.name + index}>
                <div className='tab-item'>
                  <div className='tab-head'>
                    <img src={res.icon} alt="" />
                    <span>{res.name}</span>
                  </div>
                  <div className='tab-foot'>
                    {res?.imgList.map((item, key) => (
                      <div className='card-bg' key={key}> <img src={item} alt="" /></div>
                    ))}
                  </div>
                </div>
                {index + 1 !== BaseList.length && <div className='lines' />}
              </Fragment>
            )
          })}
          {tabValue === 'Taiko' && TaikoList && TaikoList.map((res, index) => {
            return (
              <Fragment key={res.name + index}>
                <div className='tab-item'>
                  <div className='tab-head'>
                    <img src={res.icon} alt="" />
                    <span>{res.name}</span>
                  </div>
                  <div className='tab-foot'>
                    {res?.imgList.map((item, key) => (
                      <div className='card-bg' key={key}> <img src={item} alt="" /></div>
                    ))}
                  </div>
                </div>
                {index + 1 !== TaikoList.length && <div className='lines' />}
              </Fragment>
            )
          })}
        </div>
      </div>
      <div className='technical-roadmap' id='Roadmap'>
        {/* <img className='technical-title' src={TechnicalRoadmapRoad} alt="" /> */}
        <div className='technical-title'>Technical Roadmap</div>
        <HorizontalScroll>
          <div className='stepBar'>
            <div className='dashed-line'></div>
            {steplist && steplist.map(res => {
              return (
                <div className='items' key={res.id} onClick={() => setCatdId(res.id)} style={{ minWidth: res.width }}>
                  <div className='date'>{res.date}</div>
                  <div className='technical-ball'>
                    <div className='colored-circle'></div>
                  </div>
                  <div className={`cardContent ${catdId === res.id && 'selectCard'}`}>
                    {res.catdList.map((item, index) => {
                      return (
                        <div className='row' key={item.value + index}>
                          {item.status === 'unfinished' && <div className='unfinished'></div>}
                          {item.status === 'completed' && <img src={completed} alt="" />}
                          {item.status === 'progress' && <div className='card-ball'><div className='circle' /></div>}
                          <div className='text'>{item.value}</div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
        </HorizontalScroll>
      </div>
      <div className='tokenomics' id='Tokenomics'>
        <div className='left-div'>
          <div className='highlight'>Tokenomics</div>
          <img className='title' src={TokenAIBotToken} alt="" />
          <div className='supply'>
            <span className='name'>Total Supply: </span>
            <span className='value'>10,000,000 $AIBot</span>
          </div>
          <div className='left-content'>
            {leftIllustrate && leftIllustrate.map((res, index) => {
              return (
                <div className='items' key={res.value + index}>
                  <img className='icon' src={res.icon} alt="" />
                  <div className='items-text'>
                    <span className='name'>{res.name}: </span>
                    <span className='value'>  {res.value}</span>
                  </div>
                </div>
              )
            })}
            <div className='divider' />
            {leftIllustrateTwo && leftIllustrateTwo.map((res, index) => {
              return (
                <div className='items' key={res.name + index}>
                  <img className='icon' src={res.icon} alt="" />
                  <div className='items-text'>
                    <span className='name'>{res.name}: </span>
                    <span className='value'>  {res.value}</span>
                  </div>
                </div>
              )
            })}
          </div>

        </div>
        <div className='segmentation' />
        <div className='right-div'>
        <div className='divider' />
          <div className='highlight'>TOKEN DISTRIBUTION</div>
          {TOKENDISTRIBUTION && TOKENDISTRIBUTION.map(res => {
            return (
              <div className='progress' key={res.name}>
                <div className='title'>{res.value}</div>
                <div className='value'>{res.name}</div>
                <Progress percent={res.schedule} showInfo={false} strokeWidth={4} strokeColor={res.color} />
              </div>
            )
          })}
          <div className='vesting'>
            <div className='title'>VESTING</div>
            <div className='content'>
              <span className='name'>Team: </span>
              <span className='value'> 0% TGE, 6 months cliff, linear for 9 months</span>
            </div>
            <div className='content'>
              <span className='name'>Seed: </span>
              <span className='value'> 33% TGE, linear for 1 month</span>
            </div>
          </div>

        </div>
        <img className='leftImg' src={leftImg} alt="" width={394} />
        <img className='rightImg' src={rightImg} alt="" width={168} />
      </div>
      <div className='solutions' id='Solutions'>
        <div className='highlight'>Our Solutions</div>
        <div className='title'>AIBot Features</div>
        {/* <img className='title' src={AIBotFeaturesImg} alt="" /> */}
        <div className='tips'>
          Make your airdrop farming a breeze. Our automated bot operates on various chains to pick out the most advantageous airdrop opportunities, while simultaneouly implementing robust security measures to safeguard our users against hackers and fraudulent activities.
        </div>

        <div className='imgList'>
          {AIBotFeatures && AIBotFeatures.map(res => {
            return (
              <div className='items' key={res.name}>
                <div className='imgs'><img src={res.img} alt="" width={res.width}  /></div>
                <div className='content'>
                  <div className='names'>{res.name}</div>
                  <div className='tips'>{res.tip}</div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='trusted-backed'>
        <div className='headCompont'>
          <div className='maskgroup' />
          <div className='titleImg'>  <img src={TRUSTEDBACKD} alt="" /> </div>
          <div className='maskgroup' />
        </div>
        <div className='trusted-list'>
          {trustedBackedList && trustedBackedList.map((res) => {
            return (
              <div className='items' key={res.id}>
                <img src={res.img} alt="" width={120} />
                <div className='name'>{res.name}</div>
              </div>
            )
          })}
        </div>
      </div>
      <div className='questions' id='FAQs'>
        <div className='highlight'>Frequently Asked Questions</div>
        {/* <img className='title' src={automated} alt="" /> */}
        <div className='title'>You asked. We answered.</div>
        <div className='questionsList'>
          <div className='leftList'>
            {questions && questions.map(res => {
              if (res.id < 3) {
                return (
                  <div className='card-div' key={res.value} onClick={() => onSelectItems(res.id)}>
                    <div className='name'>{res.value}</div>
                    {res.show && <div className='value'>Tasks: Onchain interactions picked by AIBot Experts: Bridge, swap, NFT minting, liquid provisions, deploying smart contracts... Routes: A combination of scheduled tasks configured with conditions</div>}
                  </div>
                )
              }
            })}
          </div>
          <div className='spacing' />
          <div className='rightList'>
            {questions && questions.map(res => {
              if (res.id > 2) {
                return (
                  <div className='card-div' key={res.value} onClick={() => onSelectItems(res.id)}>
                    <div className='name'>{res.value}</div>
                    {res.show && <div className='value'>Tasks: Onchain interactions picked by AIBot Experts: Bridge, swap, NFT minting, liquid provisions, deploying smart contracts... Routes: A combination of scheduled tasks configured with conditions</div>}
                  </div>
                )
              }
            })}
          </div>
        </div>
        <div className='learn-more'>Learn More</div>
      </div>
      <div className='jointheaibot'>
        <div className='highlight'>Join The AIBot</div>
        <div className='tilte'>GET AIBOT TODAY</div>
        {/* <img className='tilte' src={GETTODAY} alt="" /> */}
        <div className='tip'>
          AIBot operates on various chains and protocols. Join today and start your farming journey.
        </div>
        <div className='buttom'>Meet AIBot</div>
      </div>
      <div className='raindrop'>
        {trustedBackedList.map((res, index) => {
          return (
            <div className={`raindrop-item raindrop-item-${index + 1}`} key={res.id}>
              <img src={res.img} alt="" />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Albot